"use client";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import * as React from "react";
import { darkTheme, lightTheme } from "./theme";

type ThemeType = typeof darkTheme | typeof lightTheme;

export function ThemeRegistry({
  children,
  theme = lightTheme,
}: {
  children: React.ReactNode;
  theme?: ThemeType;
}) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
