"use client";
import { Components } from "@mui/material";
import { Theme } from "@mui/material/styles";

type MuiTextFieldStyleOverrides = Components<Theme>["MuiTextField"];

export const muiTextFieldStyles: MuiTextFieldStyleOverrides = {
  styleOverrides: {
    root: ({ theme }) => ({
      // Target the icon button within the TextField in the date picker
      "& .MuiInputAdornment-positionEnd": {
        "& .MuiSvgIcon-root": {
          fill: theme.palette.secondary.main,
        },
      },
      "&.MuiReadOnly": {
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.neutral[400],
        },
      },
    }),
  },
};
