"use client";
import { Components } from "@mui/material";
import { Theme } from "@mui/material/styles";

type MuiOutlinedInputStyleOverrides = Components<Theme>["MuiOutlinedInput"];

export const muiOutlinedInputStyles: MuiOutlinedInputStyleOverrides = {
  styleOverrides: {
    root: ({ theme }) => ({
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.info.main,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.info.main,
      },
      "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.secondary.light,
        color: theme.palette.secondary.light,
      },
      "&.Mui-error .MuiOutlinedInput-input": {
        color: theme.palette.neutral[900],
      },
      "&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.error.main,
      },
      "&.Mui-error:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.error.main,
      },
      backgroundColor: theme.palette.common.white,
      borderRadius: 8,
    }),
    input: ({ theme }) => ({ color: theme.palette.neutral[900] }),
    notchedOutline: ({ theme }) => ({
      borderRadius: 8,
      borderColor: theme.palette.info.main,
    }),
  },
};
