"use client";
import { Components } from "@mui/material";
import { Theme } from "@mui/material/styles";

type MuiButtonStyleOverrides = Components<Theme>["MuiButton"];

export const muiButtonStyles: MuiButtonStyleOverrides = {
  styleOverrides: {
    root: {
      fontWeight: "500",
      borderRadius: "8px",
      boxShadow: "none",
      textTransform: "initial",
      "&:hover": {
        boxShadow: "none",
      },
    },
    contained: ({ theme }) => ({
      "&.Mui-disabled": {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.common.white,
      },
    }),
    text: ({ theme }) => ({
      "&.Mui-disabled": {
        color: theme.palette.secondary[200],
      },
    }),
    startIcon: {
      marginRight: "2px",
      "& > *:first-of-type": {
        fontSize: "1rem",
      },
    },
    endIcon: {
      "& > *:first-of-type": {
        fontSize: "1rem",
      },
    },
    outlined: {
      border: "1px solid",
    },
  },
};
