"use client";
import { Components } from "@mui/material";
import { Theme } from "@mui/material/styles";

type MuiChipStyleOverrides = Components<Theme>["MuiChip"];

export const muiChipStyles: MuiChipStyleOverrides = {
  styleOverrides: {
    outlined: ({ theme }) => ({
      color: theme.palette.neutral.dark,
      border: `1px solid ${theme.palette.neutral[300]}`,
    }),
    filled: ({ theme }) => ({
      backgroundColor: theme.palette.neutral[100],
      color: theme.palette.neutral.dark,
    }),
    deleteIcon: ({ theme }) => ({
      color: theme.palette.neutral[300],
    }),
  },
};
