"use client";
import { Components } from "@mui/material";
import { Theme } from "@mui/material/styles";

type MuiPaginationItemStyleOverrides = Components<Theme>["MuiPaginationItem"];

export const muiPaginationItemStyles: MuiPaginationItemStyleOverrides = {
  styleOverrides: {
    root: ({ theme }) => ({
      "&.Mui-selected .MuiPaginationItem-circular": {
        backgroundColor: theme.palette.neutral.light,
      },
    }),
  },
};
