"use client";
import { Components } from "@mui/material";
import { Theme } from "@mui/material/styles";

type MuiAlertStyleOverrides = Components<Theme>["MuiAlert"];

export const muiAlertStyles: MuiAlertStyleOverrides = {
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      ...(ownerState.severity === "info" && {
        backgroundColor: theme.palette.info.light,
      }),
      ...(ownerState.severity === "info" &&
        ownerState.variant === "filled" && {
          backgroundColor: theme.palette.info.main,
        }),
      ...(ownerState.severity === "info" &&
        ownerState.variant === "outlined" && {
          backgroundColor: "transparent",
        }),
    }),
  },
};
