"use client";
import { Components } from "@mui/material";
import { Theme } from "@mui/material/styles";

type MuiStepIconStyleOverrides = Components<Theme>["MuiStepIcon"];

export const muiStepIconStyles: MuiStepIconStyleOverrides = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.common.white,
      backgroundColor: theme.palette.info.main,
      borderColor: theme.palette.info.main,
      borderWidth: 1,
      borderStyle: "solid",
      borderRadius: "50%",
      "&.Mui-active": {
        fill: theme.palette.info.main,
        text: {
          fill: theme.palette.common.white,
        },
      },
      "&.Mui-completed": {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.info.main,
      },
    }),
    text: ({ theme }) => ({
      fill: theme.palette.info.main,
    }),
  },
};
