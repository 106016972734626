"use client";
import { PaletteShadeOptions } from "@mui/material";
import { PaletteOptions, createTheme } from "@mui/material/styles";
import { PaletteAugmentColorOptions } from "@mui/material/styles/createPalette";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import { COLORS } from "./colors";
import { muiAlertStyles } from "./componentsStyleOverrides/muiAlert.styles";
import { muiAutocompleteStyles } from "./componentsStyleOverrides/muiAutocomplete.styles";
import { muiButtonStyles } from "./componentsStyleOverrides/muiButton.styles";
import { muiButtonGroupStyles } from "./componentsStyleOverrides/muiButtonGroup.styles";
import { muiChipStyles } from "./componentsStyleOverrides/muiChip.styles";
import { muiCssBaselineStyles } from "./componentsStyleOverrides/muiCssBaseline.styles";
import { muiFormControlLabelStyles } from "./componentsStyleOverrides/muiFormControlLabel.styles";
import { muiInputLabelStyles } from "./componentsStyleOverrides/muiInputLabel.styles";
import { muiMultiSectionDigitalClockSectionStyles } from "./componentsStyleOverrides/muiMultiSectionDigitalClockSection.styles";
import { muiOutlinedInputStyles } from "./componentsStyleOverrides/muiOutlinedInput.styles";
import { muiPaginationItemStyles } from "./componentsStyleOverrides/muiPaginationItem.styles";
import { muiPickersDayStyles } from "./componentsStyleOverrides/muiPickersDay.styles";
import { muiPickersYearStyles } from "./componentsStyleOverrides/muiPickersYear.styles";
import { muiStepIconStyles } from "./componentsStyleOverrides/muiStepIcon.styles";
import { muiTextFieldStyles } from "./componentsStyleOverrides/muiTextField.styles";
import { muiToggleButtonStyles } from "./componentsStyleOverrides/muiToggleButton.styles";
import { muiTooltipStyles } from "./componentsStyleOverrides/muiTooltip.styles";
import { Typography } from "./typography";

const baseTheme = createTheme();

const augmentColor = ({ color }: PaletteAugmentColorOptions) =>
  baseTheme.palette.augmentColor({ color });

// not only theme.palette.neutral.main/light/dark but also theme.palette.neutral[600]
const createColorStructure = (color: PaletteShadeOptions) => ({
  ...augmentColor({ color }),
  50: color[50],
  100: color[100],
  200: color[200],
  300: color[300],
  400: color[400],
  500: color[500],
  600: color[600],
  700: color[700],
  800: color[800],
  900: color[900],
});

export const LightThemePalette: PaletteOptions = {
  mode: "light",
  primary: createColorStructure(COLORS.PRIMARY_BLUE),
  secondary: createColorStructure(COLORS.SECONDARY_BLUE),
  error: createColorStructure(COLORS.ERROR),
  warning: createColorStructure(COLORS.WARNING),
  info: createColorStructure(COLORS.INFO),
  success: createColorStructure(COLORS.SUCCESS),
  neutral: createColorStructure(COLORS.NEUTRAL),
  secondaryInfo: createColorStructure(COLORS.SECONDARY_INFO),
  green: createColorStructure(COLORS.PRIMARY_GREEN),
};

export const DarkThemePalette: PaletteOptions = {
  mode: "dark",
  primary: createColorStructure(COLORS.PRIMARY_BLUE_DARK),
  secondary: createColorStructure(COLORS.SECONDARY_BLUE_DARK),
  error: createColorStructure(COLORS.ERROR_DARK),
  warning: createColorStructure(COLORS.WARNING_DARK),
  info: createColorStructure(COLORS.INFO_DARK),
  success: createColorStructure(COLORS.SUCCESS_DARK),
  neutral: createColorStructure(COLORS.NEUTRAL_DARK),
  secondaryInfo: createColorStructure(COLORS.SECONDARY_INFO_DARK),
  green: createColorStructure(COLORS.PRIMARY_GREEN_DARK),
  background: {
    default: COLORS.PRIMARY_BLUE_DARK[900],
    paper: COLORS.PRIMARY_BLUE_DARK[800],
  },
  text: {
    primary: "#ffffff",
    secondary: "#e0e0e0",
  },
};

const themeConfig = createTheme({
  typography: Typography,
  components: {
    MuiAlert: muiAlertStyles,
    MuiAutocomplete: muiAutocompleteStyles,
    MuiInputLabel: muiInputLabelStyles,
    MuiButton: muiButtonStyles,
    MuiButtonGroup: muiButtonGroupStyles,
    MuiOutlinedInput: muiOutlinedInputStyles,
    MuiPaginationItem: muiPaginationItemStyles,
    MuiTextField: muiTextFieldStyles,
    MuiToggleButton: muiToggleButtonStyles,
    MuiPickersDay: muiPickersDayStyles,
    MuiPickersYear: muiPickersYearStyles,
    MuiTooltip: muiTooltipStyles,
    MuiChip: muiChipStyles,
    MuiMultiSectionDigitalClockSection: muiMultiSectionDigitalClockSectionStyles,
    MuiStepIcon: muiStepIconStyles,
    MuiFormControlLabel: muiFormControlLabelStyles,
    MuiCssBaseline: muiCssBaselineStyles,
    // TS Performance improvement
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any,
});

export const lightTheme = createTheme({
  palette: LightThemePalette,
  typography: themeConfig.typography,
  // TS Performance improvement
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  components: themeConfig.components as any,
});

export const darkTheme = createTheme({
  palette: DarkThemePalette,
  typography: themeConfig.typography,
  components: {
    ...themeConfig.components,
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${COLORS.NEUTRAL_DARK[700]}`,
          color: "#ffffff",
        },
        head: {
          backgroundColor: COLORS.PRIMARY_BLUE_DARK[800],
          color: "#ffffff",
        },
        body: {
          backgroundColor: COLORS.PRIMARY_BLUE_DARK[900],
        },
      },
    },
    // TS Performance improvement
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any,
});
