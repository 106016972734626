"use client";
import { Components } from "@mui/material";
import { Theme } from "@mui/material/styles";

type MuiPickersYearStyleOverrides = Components<Theme>["MuiPickersYear"];

export const muiPickersYearStyles: MuiPickersYearStyleOverrides = {
  styleOverrides: {
    yearButton: ({ theme }) => ({
      "&.Mui-selected": {
        backgroundColor: theme.palette.secondary.main,
        "&:hover": {
          backgroundColor: theme.palette.secondary.main,
        },
      },
    }),
  },
};
