"use client";
import { Components } from "@mui/material";
import { Theme } from "@mui/material/styles";

type MuiTooltipStyleOverrides = Components<Theme>["MuiTooltip"];

export const muiTooltipStyles: MuiTooltipStyleOverrides = {
  styleOverrides: {
    tooltip: ({ theme }) => ({
      backgroundColor: theme.palette.neutral.dark,
    }),
  },
};
