"use client";
import { Components } from "@mui/material";
import { Theme } from "@mui/material/styles";

type muiCssBaselineOverrides = Components<Theme>["MuiCssBaseline"];

export const muiCssBaselineStyles: muiCssBaselineOverrides = {
  styleOverrides: (theme) => ({
    "*::-webkit-scrollbar": {
      width: "0.25rem",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.neutral[200],
      borderRadius: "4px",
    },
    ".mui-ilbh8s": {
      boxShadow: "none !important",
    },
    "&.MuiReadOnly": {
      "& .MuiFormLabel-root": {
        color: theme.palette.neutral[500],
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.neutral[400],
      },
    },
  }),
};
