"use client";
import { Components } from "@mui/material";
import { Theme } from "@mui/material/styles";

type MuiInputLabelStyleOverrides = Components<Theme>["MuiInputLabel"];

export const muiInputLabelStyles: MuiInputLabelStyleOverrides = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.secondary.main,
      "&.Mui-focused": {
        color: theme.palette.secondary.main,
      },
      "&.Mui-error.Mui-focused": {
        color: theme.palette.error.main,
      },
      "&.Mui-disabled": {
        color: theme.palette.secondary.light,
      },
    }),
  },
};
