"use client";
import { Components } from "@mui/material";
import { Theme } from "@mui/material/styles";

type MuiPickersDayStyleOverrides = Components<Theme>["MuiPickersDay"];

export const muiPickersDayStyles: MuiPickersDayStyleOverrides = {
  styleOverrides: {
    root: ({ theme }) => ({
      "&.Mui-selected": {
        backgroundColor: theme.palette.secondary.main,
        "&:hover": {
          backgroundColor: theme.palette.secondary.main,
        },
      },
    }),
  },
};
