"use client";
import { Components } from "@mui/material";
import { Theme } from "@mui/material/styles";

type MuiButtonGroupStyleOverrides = Components<Theme>["MuiButtonGroup"];

export const muiButtonGroupStyles: MuiButtonGroupStyleOverrides = {
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      },
      "& .MuiButtonGroup-firstButton": {
        ...(ownerState.variant === "contained" && {
          borderColor: theme.palette.common.white,
        }),
      },
    }),
  },
};
