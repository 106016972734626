import { ExtendedTypographyOptions } from "../../../theme-augmentation";

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    label1: true;
    label2: true;
  }
}

export const Typography: ExtendedTypographyOptions = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  h1: {
    fontSize: "1.5rem", // 24px
    fontWeight: 400,
  },
  h2: {
    fontSize: "1.25rem", // 20px
    fontWeight: 400,
  },
  h3: {
    fontSize: "1.125rem", // 18px
    fontWeight: 400,
  },
  h4: {
    fontSize: "1rem", // 16px
    fontWeight: 400,
  },
  h5: {
    fontSize: "0.875rem", // 14px
    fontWeight: 400,
  },
  h6: {
    fontSize: "0.75rem", // 12px
    fontWeight: 400,
  },
  label1: {
    fontSize: "0.75rem", // 12px
    fontWeight: 400,
  },
  label2: {
    fontSize: "0.625rem", // 10px
    fontWeight: 400,
  },
};
