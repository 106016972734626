"use client";
import { Components } from "@mui/material";
import { Theme } from "@mui/material/styles";

type MuiMultiSectionDigitalClockSectionStyleOverrides =
  Components<Theme>["MuiMultiSectionDigitalClockSection"];

export const muiMultiSectionDigitalClockSectionStyles: MuiMultiSectionDigitalClockSectionStyleOverrides =
  {
    styleOverrides: {
      item: ({ theme }) => ({
        "&.Mui-selected": {
          backgroundColor: theme.palette.secondary.main,
          "&:hover": {
            backgroundColor: theme.palette.secondary.main,
          },
        },
      }),
    },
  };
