"use client";
import { Components } from "@mui/material";
import { Theme } from "@mui/material/styles";

type MuiAutocompleteStyleOverrides = Components<Theme>["MuiAutocomplete"];

export const muiAutocompleteStyles: MuiAutocompleteStyleOverrides = {
  styleOverrides: {
    root: ({ theme }) => ({
      "& .MuiOutlinedInput-root": {
        borderRadius: 8,
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.info.main,
        },
      },
      "&.MuiReadOnly": {
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.neutral[400],
        },
      },
    }),
  },
};
