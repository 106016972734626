"use client";
import { Components } from "@mui/material";
import { Theme } from "@mui/material/styles";

type MuiToggleButtonStyleOverrides = Components<Theme>["MuiToggleButton"];

export const muiToggleButtonStyles: MuiToggleButtonStyleOverrides = {
  styleOverrides: {
    root: ({ theme }) => ({
      "&.Mui-selected": {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
        "&:hover": {
          backgroundColor: theme.palette.secondary.dark,
        },
      },
      backgroundColor: theme.palette.common.white,
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
      textTransform: "initial",
      borderRadius: "8px",
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
    }),
  },
};
