"use client";
import { Components } from "@mui/material";
import { Theme } from "@mui/material/styles";

type MuiFormControlLabelStyleOverrides = Components<Theme>["MuiFormControlLabel"];

export const muiFormControlLabelStyles: MuiFormControlLabelStyleOverrides = {
  styleOverrides: {
    label: { fontSize: "0.875rem" },
  },
};
